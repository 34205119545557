<template>
  <base-puzzle username="Pazzle" :challenges="challenges" @continue="end">
    <template #header>
      <b-card-title>
        Admin account
      </b-card-title>
    </template>

    <template #fields="{done}">
      <auto-field value="P@zzle-Admin" @done="done($event)">
        <template #description>Pick a username</template>
        <template #feedback="{value}">{{ value }} is available</template>
      </auto-field>
    </template>

    <template #continue>
      Sign up!
    </template>
  </base-puzzle>
</template>

<script>
import BasePuzzle from "@/components/BasePuzzle";
import AutoField from "@/components/AutoField";

export default {
  components: {AutoField, BasePuzzle},
  data() {
    return {
      challenges: [
        {text: `Must contain at least 10 characters`, test: /.{10,}/},
        {text: `Must contain a special character from a valid email address`, test: /[@\-_.]/},
        {text: `Must contain at least two numbers`, test: /[0-9].*[0-9]/},
        {text: `Must contain at least two uppercase letters`, test: /[A-Z].*[A-Z]/},
        {text: `Must contain at least one repeating symbol`, test: /(.)(?=\1)/},
      ]
    }
  },
  methods: {
    end() {
      this.$bvModal.msgBoxOk('You finished the game! More levels coming soon')
    }
  }
}
</script>
